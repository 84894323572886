import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import HeroBanner from "../components/hero";
import { Helmet } from "react-helmet";
import LayoutNew from "../components/LayoutNew";

const ThankYouPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
				pageData: wpPage(slug: { eq: "thank-you" }) {
					thankYouFields {
						thankYouCta {
							thankYouCtaHeading
							thankYouCtaSubHeading
							thankYouButton {
								title
								target
								url
							}
							thankYouWebinarDescription
							thankYouMemberImage {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					seoFields {
						title
						opengraphTitle
						opengraphDescription
						metaDescription
						localBusinessSchema
						image {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		`
	);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, thankYouFields },
	} = data;

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(seoFields?.localBusinessSchema)}
				</script>
			</Helmet>
			<GatsbySeo title="Thank You Page" language="en" noindex />
			<LayoutNew>
				<div style={{ minHeight: "calc(100vh - 220px)" }}>
					<HeroBanner
						heading={thankYouFields?.thankYouCta.thankYouCtaHeading}
						subHeading={thankYouFields?.thankYouCta.thankYouCtaSubHeading}
						webinarDescription={
							thankYouFields?.thankYouCta.thankYouWebinarDescription
						}
						btnCta1={thankYouFields?.thankYouCta.thankYouButton}
						isDefault={false}
						memberImage={thankYouFields?.thankYouCta.thankYouMemberImage}
					/>
				</div>
			</LayoutNew>
		</>
	);
};
export default ThankYouPage;
